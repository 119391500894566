const initialState = {
    product: {},
    photos: {},
    reviews: [],
    questions: [],
    edost: [],
    isEdostLoading: false,
    brand: {},
    sert: {}
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT':
            return {...state, product: action.payload}
        case 'FETCH_PHOTOS':
            return {...state, photos: action.payload}
        case 'FETCH_REVIEWS':
            return {...state, reviews: action.payload}
        case 'FETCH_QUESTIONS':
            return {...state, questions: action.payload}
        case 'FETCH_EDOST':
            return {...state, edost: action.payload}
        case 'SET_EDOST_LOADING':
            return {...state, isEdostLoading: action.payload}
        case 'FETCH_BRAND':
            return {...state, brand: action.payload}
        case 'FETCH_SERT':
            return {...state, sert: action.payload}
        default:
            return state
    }
}